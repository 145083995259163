import React from 'react'
import styles from './Privacy.module.scss';

function Privacy() {
    return (
        <div className={styles.Privacy}>
            <h2>Privacy Policies</h2>

            <p className={styles.intro}>
                Welcome to Minekids Pre-school & Creche Your privacy is important to us,
                and we are committed to protecting the personal information you share with us.
                This Privacy Policy explains how we collect, use, and safeguard your information
                when you visit our website or use our services.
            </p>

            <section className={styles.section}>
                <h3>1. Information We Collect</h3>
                <p>We may collect the following types of information:</p>
                <ul>
                    <li>
                        <strong>Personal Information:</strong> Name, contact details (email, phone number,
                        address), child's name, age, and medical details shared during registration or inquiries.
                    </li>
                    <li>
                        <strong>Payment Information:</strong> Details necessary for fee processing via
                        secure payment gateways.
                    </li>
                    <li>
                        <strong>Technical Information:</strong> IP addresses, browser type, device details,
                        and website usage statistics through cookies and analytics tools.
                    </li>
                    <li>
                        <strong>Images and Media:</strong> Photos or videos of your child, with your prior
                        consent, for promotional or educational purposes.
                    </li>
                </ul>
            </section>

            <section className={styles.section}>
                <h3>2. How We Use Your Information</h3>
                <p>We use your information to:</p>
                <ul>
                    <li>Process registrations and maintain student records.</li>
                    <li>Communicate important updates about your child's activities, health, and academic progress.</li>
                    <li>Provide personalized services, including dietary and medical accommodations.</li>
                    <li>Enhance user experience on our website through analytics.</li>
                    <li>Share newsletters, upcoming events, or promotional materials.</li>
                    <li>Ensure security and compliance with legal obligations.</li>
                </ul>
            </section>

            <section className={styles.section}>
                <h3>3. Sharing of Information</h3>
                <p>
                    We do not sell, rent, or trade your personal information. We may share it
                    only in the following circumstances:
                </p>
                <ul>
                    <li>
                        <strong>With Service Providers:</strong> To facilitate payment processing,
                        IT support, or analytics.
                    </li>
                    <li>
                        <strong>With Consent:</strong> Sharing photos or videos of your child for
                        newsletters or social media posts.
                    </li>
                    <li>
                        <strong>Legal Obligations:</strong> If required by law or to protect the
                        safety of our community.
                    </li>
                </ul>
            </section>

            <section className={styles.section}>
                <h3>9. Contact Us</h3>
                <div className={styles.contact}>
                    <p><strong>Minekids</strong></p>
                    <p>Address: MDL-7, Shanti Vihar, H.B Colony Baramunda, Bhubaneswar</p>
                    <p>Phone: +91 6371864512</p>
                </div>
            </section>
        </div>
    )
}

export default Privacy