import axios from "axios";

let token = "";
if (token) token = JSON.parse(token);

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
	baseURL: `${REACT_APP_API_URL}/api`,
	headers: {
		authorization: token,
	},
});

export default axiosInstance;
