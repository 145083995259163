import React, { useState } from "react";
import { FiUpload } from "react-icons/fi";
import { toast } from "react-toastify";
import C1 from "../../assets/images/C1.jpeg";
import C2 from "../../assets/images/C2.jpeg";
import C3 from "../../assets/images/C3.jpeg";
import C4 from "../../assets/images/C4.jpeg";
import ContactUsMap from "../../components/ContactUsMap/ContactUsMap";
import axiosInstance from "../../components/Hooks/axiosInstance";
import SetTitle from "../../components/SetTitle";
import styles from "./ContactUs.module.scss";

const ContactUs = () => {
	SetTitle("Contact Us");

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [number, setNumber] = useState("");
	const [address, setAddress] = useState("");
	const [message, setMessage] = useState("");

	const [uploading, setUploading] = useState(false);
	const [vacancyFile, setVacancyFile] = useState(null);
	const [title, setTitle] = useState(null);
	const [previewFile1, setPreviewFile1] = useState(null);
	const [previewFile2, setPreviewFile2] = useState(null);
	const [previewFile3, setPreviewFile3] = useState(null);
	const [previewFile4, setPreviewFile4] = useState(null);

	const handleChangeFile = ({ file, title }) => {
		const prevUrl = URL.createObjectURL(file);
		if (title === "Teacher") {
			setPreviewFile1(prevUrl);
			setPreviewFile3(null);
			setPreviewFile2(null);
			setPreviewFile4(null);
		} else if (title === "Receptionist") {
			setPreviewFile2(prevUrl);
			setPreviewFile1(null);
			setPreviewFile3(null);
			setPreviewFile4(null);
		} else if (title === "Attendant") {
			setPreviewFile3(prevUrl);
			setPreviewFile1(null);
			setPreviewFile2(null);
			setPreviewFile4(null);
		} else if (title === "Security Guard") {
			setPreviewFile4(prevUrl);
			setPreviewFile1(null);
			setPreviewFile3(null);
			setPreviewFile2(null);
		}
		setTitle(title);
		setVacancyFile(file);
	}

	const handelSend = () => {
		if (!name || !email || !number || !address) {
			return toast.warn("Name,email,phone and address are required field...");
		}
		setUploading(true);

		axiosInstance
			.post(`/contact-us/create`, {
				name,
				email,
				phone: number,
				address,
				message,
			})
			.then(({ data }) => {
				toast.success(data?.message || "Contact us form submitted successfully. We will reach you soon ...");
				setName("");
				setEmail("");
				setMessage("");
				setAddress("");
				setNumber("");
			})
			.catch((err) => {
				console.log(err);
				toast.error(err?.response?.data?.message || "Something went wrong...");
			})
			.finally(() => setUploading(false));
	};

	const handleVacancyUpload = () => {
		if (!vacancyFile) {
			return toast.warn("Please select a file to upload...");
		}
		setUploading(true);

		const formData = new FormData();
		formData.append("cv", vacancyFile);
		formData.append("title", title);

		// for (let pair of formData.entries()) {
		// 	console.log(`${pair[0]}:`, pair[1]);
		// }

		axiosInstance
			.post(`/vacancy/create`, formData)
			.then(({ data }) => {
				toast.success(data?.message || "Vacancy uploaded successfully...");
				setVacancyFile(null);
				setTitle(null);
			})
			.catch((err) => {
				console.log(err);
				toast.error(err?.response?.data?.message || "Something went wrong...");
			})
			.finally(() => setUploading(false));
	}

	return (
		<div className={styles.ContactUs}>
			<h2>Contact Us</h2>

			<div className={styles.Container1}>
				<ContactUsMap />
			</div>

			<div className={styles.Container2}>
				<div className={styles.PartnerContainer}>
					<h3>Contact Us</h3>
					<div className={styles.FormContainer}>
						<div className={styles.InputContainer}>
							<label>Name</label>
							<input type="text" value={name} onChange={(e) => setName(e.target.value)} />
						</div>
						<div className={styles.InputContainer}>
							<label>Email</label>
							<input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
						</div>
						<div className={styles.InputContainer}>
							<label>Phone</label>
							<input type="number" value={number} onChange={(e) => setNumber(e.target.value)} />
						</div>
						<div className={styles.InputContainer}>
							<label>Address</label>
							<input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
						</div>
						<div className={styles.InputContainer}>
							<label>Message</label>
							<textarea defaultValue={message} onChange={(e) => setMessage(e.target.value)}></textarea>
						</div>
						{uploading ? <button style={{ cursor: "none" }}>Uploading...</button> : <button onClick={handelSend}>Send</button>}
					</div>
				</div>
			</div>

			<div className={styles.Container3}>
				<h3>Careers</h3>

				<div className={styles.LeftContainer}>
					<div className={styles.Left}>
						<h4>vacancy for the post of Teacher's</h4>
						<span></span>
						<ul>
							<li>Graduate/ Post Graduate</li>
							<li>NTT/PTT/IMTTI</li>
							<li>Minimum Experience & Skill Requirements</li>
							<li>1-2 Years of working experience in classroom Management</li>
							<li>Knowledge of MS- Office</li>
						</ul>
						<p className={styles.Apply}>Apply Now</p>
						<div className={styles.Wrapper}>
							<div className={styles.Upload}>
								{previewFile1 ? (
									<img src={previewFile1} alt="" />
								) : (
									<label htmlFor="TeacherFile">
										<FiUpload />
										<p>Upload your Resume</p>
									</label>
								)}
							</div>
							<button onClick={handleVacancyUpload} disabled={uploading}>
								{uploading ? "Uploading..." : "Upload"}
							</button>
							<input
								type="file"
								id="TeacherFile"
								style={{ display: "none" }}
								onChange={(e) => handleChangeFile({ file: e.target.files[0], title: "Teacher" })}
							/>
						</div>					</div>
					<div className={styles.Right}>
						<img src={C1} alt="" />
					</div>
				</div>

				<div className={styles.RightContainer}>
					<div className={styles.Left}>
						<img src={C2} alt="" />
					</div>
					<div className={styles.Right}>
						<h4>Vacancy for the post of Receptionist</h4>
						<span></span>
						<ul>
							<li>Graduate/ Post Graduate</li>
							<li>Minimum Experience & Skill Requirements</li>
							<li>1-2 Years of working experience in Office Management</li>
							<li>Knowledge of MS- Office</li>
						</ul>
						<p className={styles.Apply}>Apply Now</p>
						<div className={styles.Wrapper}>
							<div className={styles.Upload}>
								{previewFile2 ? (
									<img src={previewFile2} alt="" />
								) : (
									<label htmlFor="ReceptionistFile">
										<FiUpload />
										<p>Upload your Resume</p>
									</label>
								)}
							</div>

							<button onClick={handleVacancyUpload} disabled={uploading}>
								{uploading ? "Uploading..." : "Upload"}
							</button>
							<input
								type="file"
								id="ReceptionistFile"
								style={{ display: "none" }}
								onChange={(e) => handleChangeFile({ file: e.target.files[0], title: "Receptionist" })}
							/>
						</div>
					</div>
				</div>

				<div className={styles.LeftContainer}>
					<div className={styles.Left}>
						<h4>Vacancy for the post of Attendant</h4>
						<span></span>
						<ul>
							<li>Matriculation</li>
							<li>Minimum Experience & Skill Requirements</li>
							<li>1-2 Years of working experience</li>
						</ul>
						<p className={styles.Apply}>Apply Now</p>
						<div className={styles.Wrapper}>
							<div className={styles.Upload}>
								{previewFile3 ? (
									<img src={previewFile3} alt="" />
								) : (
									<label htmlFor="Attendantfile">
										<FiUpload />
										<p>Upload your Resume</p>
									</label>
								)}
							</div>

							<button onClick={handleVacancyUpload} disabled={uploading}>
								{uploading ? "Uploading..." : "Upload"}
							</button>
							<input
								type="file"
								id="Attendantfile"
								style={{ display: "none" }}
								onChange={(e) => handleChangeFile({ file: e.target.files[0], title: "Attendant" })}
							/>
						</div>
					</div>
					<div className={styles.Right}>
						<img src={C3} alt="" />
					</div>
				</div>

				<div className={styles.RightContainer}>
					<div className={styles.Left}>
						<img src={C4} alt="" />
					</div>
					<div className={styles.Right}>
						<h4>Vacancy for the post of Security Guard</h4>
						<span></span>
						<ul>
							<li>Matriculation</li>
							<li>Minimum Experience & Skill Requirements</li>
							<li>1-2 Years of working experience in Office Management</li>
						</ul>
						<p className={styles.Apply}>Apply Now</p>
						<div className={styles.Wrapper}>
							<div className={styles.Upload}>
								{previewFile4 ? (
									<img src={previewFile4} alt="" />
								) : (
									<label htmlFor="Guardfile">
										<FiUpload />
										<p>Upload your Resume</p>
									</label>
								)}
							</div>

							<button onClick={handleVacancyUpload} disabled={uploading}>
								{uploading ? "Uploading..." : "Upload"}
							</button>
							<input
								type="file"
								id="Guardfile"
								style={{ display: "none" }}
								onChange={(e) => handleChangeFile({ file: e.target.files[0], title: "Security Guard" })}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactUs;
