import React, { useState } from "react";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import Form4 from "./Form4";
import styles from "./AdmissionForm.module.scss";
import { toast } from "react-toastify";
import axiosInstance from "../../components/Hooks/axiosInstance";

const AdmissionForm = () => {
	const [active, setActive] = useState("form1");
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		// Form 1 fields
		firstName: "",
		lastName: "",
		gender: "Male",
		dateOfBirth: "",

		// Form 2 fields
		classAppliedFor: "",
		religion: "",
		nationality: "Indian",
		category: "general",
		languagesKnown: [],
		residentialAddress: "",
		telNumber: "",
		faxNumber: "",
		correspondenceAddress: "",
		distanceFromSchool: "",
		preferredPhoneForSMS: "",
		emergencyContactName: "",
		emergencyContactRelation: "",
		emergencyContactNumber: "",

		// Form 3 fields (Guardian Information)
		guardianName: "",
		guardianOccupation: "",
		guardianDesignation: "",
		guardianQualification: "",
		guardianAddress: "",
		guardianMobileNumber: "",
		guardianAge: "",
		guardianInstutionName: "",
		guardianIncome: "",
		guardianNationality: "",

		// Form 4 fields
		previousSchool: "",
		siblingName: "",
		siblingSchool: " ",
		staffWardName: "",
		staffWardDesignation: ""
	});

	const updateFormData = (data) => {
		setFormData(prev => ({
			...prev,
			...data
		}));
	};

	const [fileData, setFileData] = useState({
		fatherPhoto: null,
		motherPhoto: null,
		studentPhoto: null
	});

	const handleFileUpload = (e) => {
		const { name, files } = e.target;
		const file = files[0];

		if (file) {
			setFileData(prev => ({
				...prev,
				[name]: file
			}));
		}
	};
	const handelSubmit = () => {
		if (formData.firstName && formData.telNumber) {
			const reqForm = new FormData();

			Object.keys(formData).forEach(key => {
				reqForm.append(key, formData[key]);
			});

			Object.keys(fileData).forEach(key => {
				if (fileData[key]) {
					reqForm.append(key, fileData[key]);
				}
			});

			setLoading(true);
			axiosInstance
				.post(`/admission/create`, reqForm)
				.then(({ data }) => {
					console.log(data);
					toast.success(data.message || "Addmisssion Form Submitted Succesfully")
				})
				.catch((e) => {
					console.log(e);
					toast.error("Some Error Occured");
				})
				.finally(() => {
					setLoading(false);
					setActive("form1");
				})
			// Log the complete form data
			// console.log("Form Data:");
			// for (let [key, value] of reqForm.entries()) {
			// 	console.log(key + ': ' + value);
			// }

		} else {
			toast.error("Please Provide the First Name And Telephone Number");
		}
		setActive("form1");
	};

	return (
		<div className={styles.AdmissionForm} id="top">
			<div className={styles.MainContainer}>
				<div className={styles.ContentContainer}>
					<Form1
						active={active}
						setActive={setActive}
						formData={formData}
						updateFormData={updateFormData}
						handleFileUpload={handleFileUpload}
						fileData={fileData}
					/>
					<Form2
						active={active}
						setActive={setActive}
						formData={formData}
						updateFormData={updateFormData}
					/>
					<Form3
						active={active}
						setActive={setActive}
						formData={formData}
						updateFormData={updateFormData}
					/>
					<Form4
						active={active}
						setActive={setActive}
						formData={formData}
						updateFormData={updateFormData}
						handelSubmit={handelSubmit}
						loading={loading}
					// handleSubmit={handleSubmit}
					/>
				</div>

				<div className={styles.DotContainer}>
					<button className={active === "form1" ? styles.active : ""}></button>
					<button className={active === "form2" ? styles.active : ""}></button>
					<button className={active === "form3" ? styles.active : ""}></button>
					<button className={active === "form4" ? styles.active : ""}></button>
				</div>
			</div>
		</div>
	);
};

export default AdmissionForm;
