import { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./assets/scss/index.scss";
import Header from "./components/Header/Header";
import Activities from "./pages/Activities/Activities";
import AdmissionFAQ from "./pages/AdmissionFAQ/AdmissionFAQ";
import AdmissionForm from "./pages/AdmissionForm/AdmissionForm";
import ContactUs from "./pages/ContactUs/ContactUs";
import EarlyChildrenEducation from "./pages/EarlyChildrenEducation/EarlyChildrenEducation";
import Facilities from "./pages/Facilities/Facilities";
import Franchise from "./pages/Franchise/Franchise";
import Functions from "./pages/Functions/Functions";
import FunnyVideos from "./pages/FunnyVideos/FunnyVideos";
import Gallery from "./pages/Gallery/Gallery";
import Home from "./pages/Home/Home";
import LevelOfLearning from "./pages/LevelOfLearning/LevelOfLearning";
import Message from "./pages/Message/Message";
import OnlineClasses from "./pages/OnlineClasses/OnlineClasses";
import OurPurpose from "./pages/OurPurpose/OurPurpose";
import OurSchool from "./pages/OurSchool/OurSchool";
import Vendors from "./pages/Vendors/Vendors";
import VisionAndMission from "./pages/VisionAndMission/VisionAndMission";
import Page404 from "./components/Page404/Page404";
import ReactGA from "react-ga4";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Privacy from "./pages/Privacy/Privacy";

function App() {
	const [header, setHeader] = useState(false);

	const location = useLocation();
	const { pathname } = useLocation();
	const excludeHeaderRoutes = routesToIncludes;


	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: pathname, title: pathname });
	}, [pathname]);

	return (
		<div
			className="app"
			id="app"
			onScroll={(e) => {
				if (e.target.scrollTop > 15) setHeader(true);
				else if (e.target.scrollTop === 0) setHeader(false);
			}}>
			{/* <BrowserRouter> */}
			{!excludeHeaderRoutes.includes(location.pathname) ? null : <Header header={header} />}

			<ToastContainer
				position="top-right"
				autoClose={3000}
				limit={4}
				hideProgressBar={false}
				newestOnTop={false}
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover
				theme="light"
			/>

			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/Home/OurSchool" element={<OurSchool />} />
				<Route path="/Home/OurPurpose" element={<OurPurpose />} />
				<Route path="/Activities" element={<Activities />} />
				<Route path="/AboutUs/VisionAndMission" element={<VisionAndMission />} />
				<Route path="/AboutUs/Message" element={<Message />} />
				<Route path="/Admission/AdmissionFAQ" element={<AdmissionFAQ />} />
				<Route path="/Admission/AdmissionForm" element={<AdmissionForm />} />
				<Route path="/Curricular/LevelOfLearning" element={<LevelOfLearning />} />
				<Route path="/Curricular/EarlyChildrenEducation" element={<EarlyChildrenEducation />} />
				<Route path="/Curricular/OnlineClasses" element={<OnlineClasses />} />
				<Route path="/Facilities" element={<Facilities />} />
				<Route path="/Gallery" element={<Gallery />} />
				<Route path="/Franchise" element={<Franchise />} />
				<Route path="/ContactUs" element={<ContactUs />} />
				<Route path="/Vendors" element={<Vendors />} />
				<Route path="/Functions" element={<Functions />} />
				<Route path="/FunnyVideos" element={<FunnyVideos />} />
				<Route path="/privacy-policy" element={<Privacy />} />
				<Route path="*" element={<Page404 />} />
			</Routes>
			{/* </BrowserRouter> */}
		</div>
	);
}

export default App;

const routesToIncludes = ["/", "/Home/OurSchool", "/privacy-policy", "/Home/OurPurpose", "/Activities", "/AboutUs/VisionAndMission", '/Facilities', '/Gallery', '/AboutUs/Message', '/Admission/AdmissionFAQ', '/Admission/AdmissionForm', '/Curricular/LevelOfLearning', '/Curricular/EarlyChildrenEducation', '/Curricular/OnlineClasses', '/Franchise', '/ContactUs', '/Vendors', '/Functions', '/FunnyVideos'];