import React from 'react'
import styles from './index.module.scss'
import notFound from '../../assets/images/notFound.webp'

function Page404() {
    return (
        <div className={styles.Container}>
            <div className={styles.content}>
                <img src={notFound} alt='Not Found' />
                <div className={styles.mainContent}>
                    <div className={styles.description}>
                        <h2>Page Not Found</h2>
                        <p>
                            Oops! It seems the page you're looking for doesn't exist. The link might be broken, or the page may have been moved. Please check the URL for errors or return to the homepage to continue exploring. We apologize for the inconvenience and hope you find what you're looking for!
                        </p>
                        <a href="/">Back To Home</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Page404