import React from "react";
import styles from "./AdmissionForm.module.scss";

const Form3 = ({ active, setActive, formData, updateFormData }) => {
	const handelForm3Submit = () => {
		updateFormData({
			guardianName: formData.guardianName,
			guardianAge: formData.guardianAge,
			guardianNationality: formData.guardianNationality,
			guardianQualification: formData.guardianQualification,
			guardianInstutionName: formData.guardianInstutionName,
			guardianOccupation: formData.guardianOccupation,
			guardianDesignation: formData.guardianDesignation,
			guardianAddress: formData.guardianAddress,
			guardianIncome: formData.guardianIncome,
			guardianMobileNumber: formData.guardianMobileNumber
		});
		setActive("form4");
	};

	return (
		<div className={styles.form3} style={{ display: active === "form3" ? "block" : "none" }}>
			<h2>Family Information </h2>

			<div className={styles.Container}>
				<div className={styles.Left}>
					<div className={styles.SingleInputContainer}>
						<label>Guardian Name</label>
						<input
							type="text"
							value={formData.guardianName}
							onChange={(e) => updateFormData({ guardianName: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Guardian Age</label>
						<input
							type="number"
							value={formData.guardianAge}
							onChange={(e) => updateFormData({ guardianAge: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Guardian Nationality</label>
						<input
							type="text"
							value={formData.guardianNationality}
							onChange={(e) => updateFormData({ guardianNationality: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Educational Qualification</label>
						<input
							type="text"
							value={formData.guardianQualification}
							onChange={(e) => updateFormData({ guardianQualification: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Institution</label>
						<input
							type="text"
							value={formData.guardianInstutionName}
							onChange={(e) => updateFormData({ guardianInstutionName: e.target.value })}
						/>
					</div>
				</div>

				<div className={styles.Right}>
					<div className={styles.SingleInputContainer}>
						<label>Guardian Occupation</label>
						<input
							type="text"
							value={formData.guardianOccupation}
							onChange={(e) => updateFormData({ guardianOccupation: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Designation</label>
						<input
							type="text"
							value={formData.guardianDesignation}
							onChange={(e) => updateFormData({ guardianDesignation: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Official Address</label>
						<input
							type="text"
							value={formData.guardianAddress}
							onChange={(e) => updateFormData({ guardianAddress: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Annual Income</label>
						<input
							type="text"
							value={formData.guardianIncome}
							onChange={(e) => updateFormData({ guardianIncome: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Guardian Mobile Number</label>
						<input
							type="text"
							value={formData.guardianMobileNumber}
							onChange={(e) => updateFormData({ guardianMobileNumber: e.target.value })}
						/>
					</div>

					<div className={styles.ButtonContainer}>
						<a href="#top" onClick={() => setActive("form2")}>Previous</a>
						<a href="#top" onClick={handelForm3Submit}>Next</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Form3;
