import React, { useRef } from "react";
import logo from "../../assets/images/Logo2.png";
import upload from "../../assets/images/upload.png";
import callRound from "../../assets/svg/callRound.svg";
import location from "../../assets/svg/location.svg";
import styles from "./AdmissionForm.module.scss";

const Form1 = ({ active, setActive, formData, updateFormData, handleFileUpload, fileData }) => {
	const fatherRef = useRef();
	const motherRef = useRef();
	const studentRef = useRef();

	const handelForm1Submit = () => {
		setActive("form2");
	};

	return (
		<div className={styles.form1} style={{ display: active === "form1" ? "flex" : "none" }}>
			<div className={styles.Top}>
				<div className={styles.Left}>
					<img src={logo} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>MINE KIDS PRESCHOOL</h2>
					<div className={styles.inner}>
						<img src={location} alt="" />
						<p>MDL-7, Shanti Vihar,Housing Board Colony Baramunda Bhubaneswar,pin 751003</p>
					</div>
					<div className={styles.inner}>
						<img src={callRound} alt="" />
						<p>8917327419, +91 83380 26545</p>
					</div>
				</div>
			</div>

			<h2>Application Form</h2>

			<h3>Information To child</h3>
			<div className={styles.UploadContainer}>
				{/* File upload handler component that manages form data for image uploads
				   - Uses FormData to store uploaded files
				   - Handles father, mother and student photo uploads
				   - Updates fileData state to show selected filenames
				*/}
				<div className={styles.Box} onClick={() => fatherRef.current.click()}>
					<input
						type="file"
						className={styles.hiddenInput}
						ref={fatherRef}
						name="fatherPhoto"
						onChange={handleFileUpload}
						// onChange={(e) => {
						// 	if (e.target.files[0]) {
						// 		handleFileUpload('fatherPhoto', e.target.files[0]);
						// 	}
						// }}
						accept="image/*"
					/>
					<img src={upload} alt="Upload icon" />
					<p>{fileData?.fatherPhoto?.name || "Upload Affix photo of Father"}</p>
				</div>

				<div className={styles.Box} onClick={() => motherRef.current.click()}>
					<input
						type="file"
						className={styles.hiddenInput}
						ref={motherRef}
						name="motherPhoto"
						onChange={handleFileUpload}
						// onChange={(e) => {
						// 	if (e.target.files[0]) {
						// 		handleFileUpload('motherPhoto', e.target.files[0]);
						// 	}
						// }}
						accept="image/*"
					/>
					<img src={upload} alt="Upload icon" />
					<p>{fileData?.motherPhoto?.name || "Upload Affix photo of Mother"}</p>
				</div>

				<div className={styles.Box} onClick={() => studentRef.current.click()}>
					<input
						type="file"
						className={styles.hiddenInput}
						ref={studentRef}
						name="studentPhoto"
						onChange={handleFileUpload}
						// onChange={(e) => {
						// 	if (e.target.files[0]) {
						// 		handleFileUpload('studentPhoto', e.target.files[0]);
						// 	}
						// }}
						accept="image/*"
					/>
					<img src={upload} alt="Upload icon" />
					<p>{fileData?.studentPhoto?.name || "Upload Affix photo of Child"}</p>
				</div>
			</div>

			<div className={styles.FormContainer}>
				<div className={styles.SingleInputContainer}>
					<label>First Name</label>
					<input
						type="text"
						value={formData.firstName || ''}
						onChange={(e) => updateFormData({ firstName: e.target.value })}
					/>
				</div>

				<div className={styles.SingleInputContainer}>
					<label>Last Name</label>
					<input
						type="text"
						value={formData.lastName || ''}
						onChange={(e) => updateFormData({ lastName: e.target.value })}
					/>
				</div>

				<div className={styles.DoubleInputContainer}>
					<div className={styles.LeftInput}>
						<label>Gender</label>
						<select
							value={formData.gender || ''}
							onChange={(e) => updateFormData({ gender: e.target.value })}
						>
							<option value="male">Male</option>
							<option value="female">Female</option>
						</select>
					</div>

					<div className={styles.RightInput}>
						<label>Date Of Birth</label>
						<input
							type="date"
							value={formData.dateOfBirth || ''}
							onChange={(e) => updateFormData({ dateOfBirth: e.target.value })}
						/>
					</div>
				</div>

				<a href="#top" onClick={handelForm1Submit}>
					Next
				</a>
			</div>
		</div>
	);
};

export default Form1;
