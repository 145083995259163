import React from "react";
import SetTitle from "../../components/SetTitle";
import A1 from "../../assets/images/A1.webp";
import A10 from "../../assets/images/A10.webp";
import A2 from "../../assets/images/A2.webp";
import A3 from "../../assets/images/A3.webp";
import A4 from "../../assets/images/A4.webp";
import A5 from "../../assets/images/A5.webp";
import A6 from "../../assets/images/A6.webp";
import A7 from "../../assets/images/A7.webp";
import A8 from "../../assets/images/A8.webp";
import A9 from "../../assets/images/A9.webp";
import styles from "./Activities.module.scss";

const Activities = () => {
	SetTitle("Activities");

	return (
		<div className={styles.Activities}>
			<h2>Our Activities</h2>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h3>Class presentation</h3>
					<span></span>
					<p>
						The class presentations are an event that we at Mine Kids look forward to every few months! This event is
						a quarterly culmination of the learnings of our children wherein they showcase their skills in diverse
						fields such as dance, music, recitation and yoga. At our class presentations, you can easily witness how
						well your little one is performing in school and be proud of his/her growth process. When the children get
						on the stage, they are simply magical. Their unmatched cuteness and confidence definitely deserve the
						glorious spotlight! While in the process, they also develop self-esteem and self-expression in the most
						unique way.
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A1} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={A2} alt="" />
				</div>
				<div className={styles.Right}>
					<h3>Fancy Dress</h3>
					<span></span>
					<p>
						Little children are much more capable than we can ever imagine. They can rouse your conscience on
						sensitive issues like no one can. Such are the soul stirring performances by the children at the Fancy
						Dress shows where they come wonderfully dressed up and talk about global warming, child labour, save the
						girl child and other socially relevant topics. These topics help increase their knowledge and make them
						socially responsible citizens. Their dynamic stage presence, vibrant costumes and charming personalities
						speak volumes of their confidence at such a tender age.
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h3>Fashion Show</h3>
					<span></span>
					<p>
						Playing dress up begins during the first five years and never really ends. â€˜Lights, Camera and
						Action!â€™. Here come the dazzling divas and dashing rock stars who will simply set the stage on fire at
						the very own Fashion Show of Mine Kids. Their stunning outfits, attractive personalities and confident
						walks are a true testimony to how they are trendsetters in the making! With their cute speeches they turn
						a lot of heads and stun the onlookers with their flawless delivery and dynamic stage presence. Wait for
						it! In a few years, all the best actors, models, leaders & artisans will be among the alumni of Mine Kids.
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A3} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={A4} alt="" />
				</div>
				<div className={styles.Right}>
					<h3>Story telling session</h3>
					<span></span>
					<p>
						Children have an innate love for stories. They have the power to create magic and a sense of adventure in
						the classroom. Stories are a great way to teach children about life. At Mine Kids, storytelling is a
						unique way for children to develop an understanding about the world around them. It creates interest among
						them as the character comes to life in front of them and captures their attention & imagination. Children
						learn about topics like good social habits, traffic rules and sharing through stories. They also develop
						language and imaginary skills as they narrate and listen to stories.
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h3>Intellectual Development Session</h3>
					<span></span>
					<p>
						Although, there is no big manual for mental development of kids, there certainly are great fun activities
						and amazing teacher! At Mine Kids, through meticulously designed intellectual development sessions
						children learn about various concepts. Designed around a child-centric approach the children are engaged
						in intellectual activities like solving puzzles and building towers to develop memory and observation
						skills, problem solving, sequencing and seriation. When they slide, flip, and turn things, they develop
						hand-eye coordination, analytical and fine motor skills in an interesting manner.
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A5} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={A6} alt="" />
				</div>
				<div className={styles.Right}>
					<h3>Rhymes and Songs</h3>
					<span></span>
					<p>
						Language development is yet another focus area for us and to achieve this, we teach the little ones
						through rhymes and songs. They are a great way to help anyone remember words and concepts. Using different
						rhyming words helps children develop an ear for phonics, thus, making it easy for them to create words by
						combining sounds. Teaching them rhymes & songs also helps them become better readers in future. Our little
						darlings look like a delight when they imitate the teachers by making funny faces and movements to match
						the words.
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h3>Circle Time</h3>
					<span></span>
					<p>
						Circle time is the time when friendship blossoms as children interact, socialize and share their thoughts
						and experiences with each other. Various activities are designed, creating opportunities for kids to
						experience the joy of talking and communicating with each other. On a regular day, the teacher and
						children gather together in a circle to create a rich weave of rhymes, games and songs. Developing social,
						emotional, and personal skills are some of the primary objectives of the Circle Time. The activity also
						fosters problem solving skills, language building and confidence as children are encouraged to express
						their views freely.
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A7} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={A8} alt="" />
				</div>
				<div className={styles.Right}>
					<h3>Role play</h3>
					<span></span>
					<p>
						There is no substitute for creative and imaginative play when it comes to teaching. Every child is born
						with an imagination. Allowing children to exercise their imagination enhances their creativity and creates
						better social understanding. This is the strength of children - their ability to pretend and fix problems
						with their imagination. To create a better understanding of various concepts, children are engaged in role
						plays. They become doctors, politicians and other community helpers and enact their lives. Through this
						activity, they develop love, respect, and appreciation for the world around them.
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h3>puppet play</h3>
					<span></span>
					<p>
						Children love to use their imaginations to move beyond the bounds of reality. They can turn a stick into a
						magic wand, a sock into a puppet and themselves into superheroes. Puppets are toys that truly fascinate
						children; they connect as friends. Itâ€™s also a great way to draw the attention and imagination of
						preschoolers and while offering amazing benefits that aid a childâ€™s development. By organising Puppet
						Plays, we strive to bring stories to life, and inculcate various concepts like good habits, sharing,
						caring, etc. It also helps the kids develop vocabulary and improve communication & listening skills.
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A9} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={A10} alt="" />
				</div>
				<div className={styles.Right}>
					<h3>Drawing & art</h3>
					<span></span>
					<p>
						Every child has a creative world inside him. We help our children explore that world through art and craft
						activities. They simply love playing with textures and materials as well as putting together and taking
						apart objects to create something unique, something new. It provides a new set of skills for
						self-expression, communication and endless amazement for the children. Craft work enhances their
						creativity, contributes to the pre-writing skills and develops grasping power among the children as they
						hold colours, cut patterns and draw shapes.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Activities;
