import React from "react";
import SetTitle from "../../components/SetTitle";
import F1 from "../../assets/images/F1.webp";
import F2 from "../../assets/images/F2.webp";
import F3 from "../../assets/images/F3.webp";
import F4 from "../../assets/images/F4.webp";
import F5 from "../../assets/images/F5.webp";
import F6 from "../../assets/images/F6.webp";
import F7 from "../../assets/images/F7.webp";
import styles from "./Facilities.module.scss";

const Facilities = () => {
	SetTitle("Facilities");

	return (
		<div className={styles.Facilities}>
			<h2>Our Facilities</h2>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h3>Child friendly infrastructure</h3>
					<span></span>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed
						maecenas tristique tincidunt cras. Vestibulum tincidunt sit Lorem ipsum dolor sit amet, consectetur
						adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique tincidunt cras.
						Vestibulumem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo.
						Sed maecenas tristique tincidunt cras. Vestibulum tincidunt sit tincidunt sit ipsum dolor sit amet,
						consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique{" "}
					</p>
				</div>
				<div className={styles.Right}>
					<img src={F1} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={F2} alt="" />
				</div>
				<div className={styles.Right}>
					<h3>Play Zone</h3>
					<span></span>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed
						maecenas tristique tincidunt cras. Vestibulum tincidunt sit Lorem ipsum dolor sit amet, consectetur
						adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique tincidunt cras.
						Vestibulumem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo.
						Sed maecenas tristique tincidunt cras. Vestibulum tincidunt sit tincidunt sit ipsum dolor sit amet,
						consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique{" "}
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h3>Intellectual Development Centre</h3>
					<span></span>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed
						maecenas tristique tincidunt cras. Vestibulum tincidunt sit Lorem ipsum dolor sit amet, consectetur
						adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique tincidunt cras.
						Vestibulumem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo.
						Sed maecenas tristique tincidunt cras. Vestibulum tincidunt sit tincidunt sit ipsum dolor sit amet,
						consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique{" "}
					</p>
				</div>
				<div className={styles.Right}>
					<img src={F3} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={F4} alt="" />
				</div>
				<div className={styles.Right}>
					<h3>splash pool</h3>
					<span></span>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed
						maecenas tristique tincidunt cras. Vestibulum tincidunt sit Lorem ipsum dolor sit amet, consectetur
						adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique tincidunt cras.
						Vestibulumem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo.
						Sed maecenas tristique tincidunt cras. Vestibulum tincidunt sit tincidunt sit ipsum dolor sit amet,
						consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique{" "}
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h3>Day boarding</h3>
					<span></span>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed
						maecenas tristique tincidunt cras. Vestibulum tincidunt sit Lorem ipsum dolor sit amet, consectetur
						adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique tincidunt cras.
						Vestibulumem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo.
						Sed maecenas tristique tincidunt cras. Vestibulum tincidunt sit tincidunt sit ipsum dolor sit amet,
						consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique{" "}
					</p>
				</div>
				<div className={styles.Right}>
					<img src={F5} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={F6} alt="" />
				</div>
				<div className={styles.Right}>
					<h3>Meals</h3>
					<span></span>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed
						maecenas tristique tincidunt cras. Vestibulum tincidunt sit Lorem ipsum dolor sit amet, consectetur
						adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique tincidunt cras.
						Vestibulumem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo.
						Sed maecenas tristique tincidunt cras. Vestibulum tincidunt sit tincidunt sit ipsum dolor sit amet,
						consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique{" "}
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h3>Transportation</h3>
					<span></span>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed
						maecenas tristique tincidunt cras. Vestibulum tincidunt sit Lorem ipsum dolor sit amet, consectetur
						adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique tincidunt cras.
						Vestibulumem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo.
						Sed maecenas tristique tincidunt cras. Vestibulum tincidunt sit tincidunt sit ipsum dolor sit amet,
						consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique{" "}
					</p>
				</div>
				<div className={styles.Right}>
					<img src={F7} alt="" />
				</div>
			</div>
		</div>
	);
};

export default Facilities;
