import React from "react";
import styles from "./AdmissionForm.module.scss";
import { toast } from "react-toastify";

const Form4 = ({ active, setActive, formData, updateFormData, handelSubmit, loading }) => {


	return (
		<div className={styles.form4} style={{ display: active === "form4" ? "block" : "none" }}>
			<h2>Family Information </h2>

			<div className={styles.Container}>
				<div className={styles.Left}>
					<div className={styles.SingleInputContainer}>
						<label>Previous school if any attended</label>
						<input
							type="text"
							value={formData.previousSchool}
							onChange={(e) => updateFormData({ ...formData, previousSchool: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Details of any Sibling: Name of the Child</label>
						<input
							type="text"
							value={formData.siblingName}
							onChange={(e) => updateFormData({ ...formData, siblingName: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Name of the School</label>
						<input
							type="text"
							value={formData.siblingSchool}
							onChange={(e) => updateFormData({ ...formData, siblingSchool: e.target.value })}
						/>
					</div>
				</div>

				<div className={styles.Right}>
					<div className={styles.SingleInputContainer}>
						<label>In case of Staff ward: Name of the Parent</label>
						<input
							type="text"
							value={formData.staffWardName}
							onChange={(e) => updateFormData({ ...formData, staffWardName: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Designation</label>
						<input
							type="text"
							value={formData.staffWardDesignation}
							onChange={(e) => updateFormData({ ...formData, staffWardDesignation: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label className={styles.text}>
							I hereby certify that the information given in the admission form is complete and accurate. I
							understand and agree this misrepresentation or omission of facts will be justifying the denial of
							admission, the cancellation of admission or expulsion. I have read and do hereby consent to the term
							and condition enclosed with the registration form.
						</label>
					</div>

					<div className={styles.ButtonContainer}>
						<a href="#top" disabled={loading} onClick={() => setActive("form3")}>
							Previous
						</a>
						<a href="#top" disabled={loading} onClick={handelSubmit}>
							{loading ? "Uploading..." : "Submit"}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Form4;
