import React from "react";
import styles from "./AdmissionForm.module.scss";

const Form2 = ({ active, setActive, formData, updateFormData }) => {

	const handelForm2Submit = () => {
		updateFormData({
			classAppliedFor: formData.classAppliedFor,
			religion: formData.religion,
			nationality: formData.nationality,
			category: formData.category,
			languagesKnown: formData.languagesKnown,
			residentialAddress: formData.residentialAddress,
			telNumber: formData.telNumber,
			faxNumber: formData.faxNumber,
			correspondenceAddress: formData.correspondenceAddress,
			distanceFromSchool: formData.distanceFromSchool,
			preferredPhoneForSMS: formData.preferredPhoneForSMS,
			emergencyContactName: formData.emergencyContactName,
			emergencyContactRelation: formData.emergencyContactRelation,
			emergencyContactNumber: formData.emergencyContactNumber
		});
		setActive("form3");
	};

	return (
		<div className={styles.form2} style={{ display: active === "form2" ? "block" : "none" }}>
			<h2>Information of Child</h2>
			<div className={styles.Container}>
				<div className={styles.Left}>
					<div className={styles.SingleInputContainer}>
						<label>Class for which admission is sought</label>
						<input
							type="text"
							value={formData.classAppliedFor}
							onChange={(e) => updateFormData({ classAppliedFor: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Religion</label>
						<input
							type="text"
							value={formData.religion}
							onChange={(e) => updateFormData({ religion: e.target.value })}
						/>
					</div>

					<div className={styles.DoubleInputContainer}>
						<div className={styles.LeftInput}>
							<label>Nationality</label>
							<input
								type="text"
								value={formData.nationality}
								onChange={(e) => updateFormData({ nationality: e.target.value })}
							/>
						</div>

						<div className={styles.RightInput}>
							<label>SC / ST / General</label>
							<input
								type="text"
								value={formData.category}
								onChange={(e) => updateFormData({ category: e.target.value })}
							/>
						</div>
					</div>

					<div className={styles.DoubleInputContainer}>
						<div className={styles.LeftInput}>
							<label>Languages Known</label>
							<input
								type="text"
								value={formData.languagesKnown?.[0] || ''}
								onChange={(e) => {
									const newLanguages = [...(formData.languagesKnown || ['', '', ''])];
									newLanguages[0] = e.target.value;
									updateFormData({ languagesKnown: newLanguages });
								}}
							/>
						</div>

						<div className={styles.RightInput}>
							<label></label>
							<input
								type="text"
								value={formData.languagesKnown?.[1] || ''}
								onChange={(e) => {
									const newLanguages = [...(formData.languagesKnown || ['', '', ''])];
									newLanguages[1] = e.target.value;
									updateFormData({ languagesKnown: newLanguages });
								}}
							/>
						</div>
					</div>

					<div className={styles.DoubleInputContainer}>
						<div className={styles.LeftInput}>
							<label></label>
							<input
								type="text"
								value={formData.languagesKnown?.[2] || ''}
								onChange={(e) => {
									const newLanguages = [...(formData.languagesKnown || ['', '', ''])];
									newLanguages[2] = e.target.value;
									updateFormData({ languagesKnown: newLanguages });
								}}
							/>
						</div>
						<div className={styles.RightInput}></div>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Residential Address</label>
						<textarea
							value={formData.residentialAddress}
							onChange={(e) => updateFormData({ residentialAddress: e.target.value })}
						></textarea>
					</div>

					<div className={styles.DoubleInputContainer}>
						<div className={styles.LeftInput}>
							<label>Tel</label>
							<input
								type="number"
								value={formData.telNumber}
								onChange={(e) => updateFormData({ telNumber: e.target.value })}
							/>
						</div>

						<div className={styles.RightInput}>
							<label>Fax</label>
							<input
								type="number"
								value={formData.faxNumber}
								onChange={(e) => updateFormData({ faxNumber: e.target.value })}
							/>
						</div>
					</div>
				</div>

				<div className={styles.Right}>
					<div className={styles.SingleInputContainer}>
						<label>Correspondence Address</label>
						<textarea
							value={formData.correspondenceAddress}
							onChange={(e) => updateFormData({ correspondenceAddress: e.target.value })}
						></textarea>
					</div>

					<div className={styles.DoubleInputContainer}>
						<div className={styles.LeftInput}>
							<label>Tel</label>
							<input
								type="number"
								value={formData.telNumber}
								onChange={(e) => updateFormData({ telNumber: e.target.value })}
							/>
						</div>

						<div className={styles.RightInput}>
							<label>Fax</label>
							<input
								type="number"
								value={formData.cFax}
								onChange={(e) => updateFormData({ faxNumber: e.target.value })}
							/>
						</div>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Distance from school (in Kms):</label>
						<input
							type="text"
							value={formData.distanceFromSchool}
							onChange={(e) => updateFormData({ distanceFromSchool: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Preferred Phone Number for school SMS:</label>
						<input
							type="text"
							value={formData.preferredPhoneForSMS}
							onChange={(e) => updateFormData({ preferredPhoneForSMS: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Emergency Contact Numbers Mobile No</label>
						<input
							type="text"
							value={formData.emergencyContactNumber}
							onChange={(e) => updateFormData({ emergencyContactNumber: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Name of the person to be contacted</label>
						<input
							type="text"
							value={formData.emergencyContactName}
							onChange={(e) => updateFormData({ emergencyContactName: e.target.value })}
						/>
					</div>

					<div className={styles.SingleInputContainer}>
						<label>Relationship</label>
						<input
							type="text"
							value={formData.emergencyContactRelation}
							onChange={(e) => updateFormData({ emergencyContactRelation: e.target.value })}
						/>
					</div>

					<div className={styles.ButtonContainer}>
						<a href="#top" onClick={() => setActive("form1")}>
							Previous
						</a>
						<a href="#top" onClick={handelForm2Submit}>
							Next
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Form2;
