import React, { useEffect } from "react";
import SetTitle from "../../components/SetTitle";
import styles from "./Functions.module.scss";

const ImgSrc = 'https://picsum.photos/200/300';

const Functions = () => {
	SetTitle("School Functions");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={styles.Functions}>
			<h2>Our school Functions</h2>

			<div className={styles.Wrapper}>
				<img src={ImgSrc} alt="" />
			</div>
			<div className={styles.Wrapper}>
				<img src={ImgSrc} alt="" />
			</div>
			<div className={styles.Wrapper}>
				<img src={ImgSrc} alt="" />
			</div>
			<div className={styles.Wrapper}>
				<img src={ImgSrc} alt="" />
			</div>
			<div className={styles.Wrapper}>
				<img src={ImgSrc} alt="" />
			</div>
			<div className={styles.Wrapper}>
				<img src={ImgSrc} alt="" />
			</div>
			<div className={styles.Wrapper}>
				<img src={ImgSrc} alt="" />
			</div>
		</div>
	);
};

export default Functions;
